import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/zh'
import { Seo } from 'components/index'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import * as styles from './index.module.less'

const com = (props) => {
  // const { data } = props
  // const { yaml } = data
  // const {} = yaml

  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout {...props}>
      <Seo title="" description="" keywords="" />
    </Layout>
  )
}

// export const query = graphql`
//   query {
//     yaml {

//     }
//   }
// `

export default com
